<template>
  <div class='template' :style="{backgroundImage:`url(${page?.background})`}"> 
      <article class='text-content'>
          <div class='text-content-limit-width'>
            <transition name="slide" appear>
                <div>
                    <div class='copy-padding'>
                        <div>
                            <ImageTitle transparent :title="page.title"/>              
                            <Editor @click="activateEditor" plugins="lists" api-key="2uyqbbnykquw7zpsd3ht43lzwe4s4dyu3l8x6p7vgvx8v4ug" :init="{menubar:false}" toolbar='[ undo redo | bold italic underline | fontsize forecolor | bullist numlist link ]' v-model="copy" class="copy" v-if="copy || $store.state.devMode" inline :disabled="!editorFocused" @change="updateContent"/>                        
                            <AttachmentUI :page="page" :gallery="gallery" @showGallery="galleryVisible = true" @showVideo="showVideo"/>         
                        </div>
                    </div>
                    <div class="fixed-stats" v-if="stats.length > 0">    
                        <div v-for="stat in stats" :key="stat.label">
                            <p class="value">{{stat.value}}<sup>{{stat.symbol}}</sup></p>
                            <p class="label">{{stat.label}}</p>
                        </div>                        
                    </div>                    
                </div>
            </transition>            
          </div>
          <div class="linear-holder">
            <LinearButton :to="page?.previous" previous/>
            <LinearButton :to="page?.next"/>
          </div>
      </article>      
      <transition name="fade">
        <Gallery :images="gallery" @close="galleryVisible = false" v-show="galleryVisible"/>
      </transition>
      <transition name="fade">
        <Gallery videoMode :images="[]" :videoId="videoId" @close="videoVisible = false" v-if="videoVisible" :show="videoVisible"/>
      </transition>
  </div>  
</template>

<script>
import Page from '@/mixins/Page';
import AttachmentUI from '@/components/AttachmentUI';
import LinearButton from '@/components/LinearButton';
import ImageTitle from '@/components/ImageTitle';
import Gallery from '@/components/Gallery';
export default {
    name:'Infographic Stacked',
    mixins: [Page],
    props: {
        page:Object
    },
    components:{
        AttachmentUI,
        Gallery,
        LinearButton,
        ImageTitle
    },
    data() {
        return {
            galleryVisible:false,
            videoVisible:false,
            imagesFromGallery:0                 
        }
    },
    computed: {        
        gallery() {
            let images = [];        
            for (let index = this.imagesFromGallery; index < this.page.gallery?.length; index++) {
                const element = this.page?.gallery[index];
                images.push({
                    file:element.file,
                    title:element.title
                });
            }
            return images;
        },
        copy() {
            let copy = this.page?.copy;            
            return copy.replace(/\[.*?\]/g, "");
        },
        stats() {
            let stats = this.parseShortCode(this.page?.copy)
            return stats.slice(0, 6);   //limits up to 6
        }
    },
    methods : {
        parseShortCode(shortCode) {
            const shortcodes = [];     
            const rawShortCodes = shortCode.match(/[\w-]+=".+?"/g);  
            let elementsInGroup = 3;          
            for (let index = 0; index < rawShortCodes?.length; index += elementsInGroup) {
                elementsInGroup = 3;
                const shortcode = {};
                const element1 = rawShortCodes[index].match(/([\w-]+)="(.+?)"/);
                const element2 = rawShortCodes[index + 1].match(/([\w-]+)="(.+?)"/);
                const element3 = rawShortCodes[index + 2]?.match(/([\w-]+)="(.+?)"/) ?? [];
                shortcode[element1[1]] = element1[2];
                shortcode[element2[1]] = element2[2];
                if( element3[1] !== 'symbol') {
                    elementsInGroup = 2;
                    shortcode['symbol'] = '%';
                } else {
                    shortcode[element3[1]] = element3[2] === '-' ? '': unescape(element3[2]);
                }
                shortcodes.push(shortcode);
            }                        
            return shortcodes;
        }
    }
}
</script>

<style lang="scss" scoped>
    @media (max-width:699px) {  
        .linear-holder {
            position: relative;
            height:90px;
        }
    }
    .template {
        display:grid;
        place-items: center;
        position: relative;
        background-size: cover;
        &:before {      
            content:"";
            position: absolute;
            top:0;
            left:0;
            width:100%;
            height:100%;
            background: rgba(18,18,18,0.8)
        }
    }    
    .text-content {
        -webkit-print-color-adjust: exact;   /* Chrome, Safari, Edge */
        color-adjust: exact;     
        box-sizing: border-box;
        color: #F5F5F5;
        position: relative;                        
        @media (min-width:700px) {                        
            .text-content-limit-width {
                max-width:1000px;
                margin:0 auto;                
            }
            .text-content-limit-width > div:not(.title) {
                position: relative;
                > div {
                    position: relative;
                }                
            }
            .text-content-limit-width > div {
                display:grid;
                grid-template-columns: 1fr max-content;    
                place-items: center;
                gap:30px;
                padding:0;                
                > div:not(.copy) {                    
                    max-width: 550px;                    
                    margin-left:auto;
                    width:100%;
                }
            
            }
        }
        @media print {
            > div {
                grid-template-columns: max-content 1fr;
                .copy {
                    padding-right:0;
                }
                div:not(.copy) {
                    margin-left:0;
                }
            }
        }                    
    }           
    .fixed-stats {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-auto-flow: row;   
        gap:45px;                     
        > div {
            border-left:1px solid #F5F5F5;
            display: flex;
            flex-direction: column;
            gap:22px;
            justify-content: space-between;
            padding:0 36px;
            position: relative;
            user-select: none;
            @media (hover:hover) {
                &:after {
                    content:"";
                    position: absolute;
                    top:0;
                    left:-1px;
                    width:2px;
                    height:0;
                    background:#0066CC;
                    transition: height 0.2s ease-in-out;
                }
                &:hover {
                    &:after {
                        height:100%;
                    }
                }
            }
        }
        p {
            margin:0;
        }
        .value {
            color: #F5F5F5;
            font-size: 3rem;
            font-weight: bold;
            letter-spacing: 0;
            line-height: 1;
            font-family: 'Helvetica Neue LT Std';
            font-weight: 900;
            sup {
                font-size: 0.5em;
            }
        }
        .label {
            font-family: 'Helvetica Neue LT Std';
            font-weight: bold;
            text-transform: uppercase;
            color: #F5F5F5;
            font-size: 1rem;
            letter-spacing: 0;
            line-height: 1;
        }
    }
    .text-content .title {        
        text-transform: uppercase;
        padding:0;
        padding-bottom: 45px;
    }  
    .copy-padding {
        padding:50px;
        box-sizing: border-box;
        position: relative;
        @media (min-width:700px) {
            background-color: rgba(18,18,18,1);   
            &:before {
                content:"";
                height:100%;
                width:200%;
                transform: translateX(-100%);
                background-color: rgba(18,18,18,1);   
                position: absolute;
                left:0;
                top:0;
            }
            > div {
                position: relative;
            }
        }        
    }
</style>